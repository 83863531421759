@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Roboto';
	src: local('Roboto'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoNormal';
	src: local('Acme'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'RobotoMedium';
	src: local('Acme'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}
html,
body {
	height: 100%;
}
#root {
	min-height: 100%;
}
input:checked ~ .radio {
	color:white;
	background-color: green;
}
.language-type input:checked ~ .radio {
	background-color: #CE1126;
}
.cloudinary-button {
	border-radius: 8px;
	font-size: 14px;
	padding: 12px;
	color: #FFFFFF;
	background-color: rgb(35, 47, 62);
	border: 1px solid rgb(232, 232, 232);
	box-sizing: border-box;
	margin-top: 22px;
	font-family: RobotoNormal;
}
.react-datetime-picker {
	padding: 4px;
}
.react-datetime-picker input {

}
.react-datetime-picker__wrapper {
	border: 0px;
}